import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>moved to...</h1>
        <a href="https://bikesandbarbies.com">
          <img src="https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bandb-selected-blue.png" alt="" />
        </a>
      </header>
    </div>
  );
}

export default App;
